
import pool_area_above from '../sources/pool_area_above_kineret.png'
import pool_area from '../sources/pool_area_kineret.png'
import porch_kineret from '../sources/porch_kineret.png'
export const kineret_specification = [
    {
      head: "מטבח",
      items: [
        "ארונות מטבח של חברת PANEL",
        'משטח שיש קיסר או ש"ע',
        "יחידת B.L",
        "כיור אקרילי בהתקנה שטוחה",
      ],
    },
    {
      head: "חדרי רחצה",
      items: [
        "אסלות תלויות ומכלי הדחה סמויים ",
        "אמבט אקרילי",
        "ארון אמבטיה בחדרי רחצה ",
        "ברזי פרח",
        "סוללה 4 דרך מקלחת הורים",
      ],
    },
    {
      head: "דלתות וחלונות",
      items: [
        'משקופים ודלתות חמדיה/ ש"ע',
        "דלת כניסה מעוצבת",
        'תריס גלילה חשמלי (פרט לממ"ד, ויטרינות וחדרים רטובים ומרפסת שירות)',
      ],
    },
    {
      head: "חשמל",
      items: [
        "חשמל תלת פאזי A3/25",
        "נקודת TV\\TEL בכל חדר",
        "הכנה למערכת מיזוג אוויר",
        "הכנה לחשמל חכם",
        "נקודת חשמל לתנור במקלחת הורים",
        "נקודת חשמל, גז ומים במרפסת",
      ],
    },
    {
      head: "קרמיקה",
      items: ["ריצוף גרניט פורצלן 80/80", "קרמיקה בקירות 60/120"],
    },
  ];
  export const kineret_departments = [
    {
      name: "3 חדרים",
      type: "C",
      pic: '',
      house: "77 מטרים",
      porch: "16 מטרים",
    },
    {
      name: "4 חדרים",
      type: "A1",
      pic: '',
      house: "104 מטרים",
      porch: "19 מטרים",
    },
    {
      name: "4 חדרים - מיני פנטהאוז",
      type: "A2",
      pic: '',
      house: "104 מטרים",
      porch: "35 מטרים",
    },
    {
      name: "4 חדרים - גן",
      type: "B",
      pic: '',
      house: "104 מטרים",
      garden: "70 מטרים",
    },
  
    {
      name: "5 חדרים",
      type: "B1",
      pic: '',
      house: "120 מטרים",
      porch: "21 מטרים",
    },
    {
      name: "5 חדרים - מיני פנטהאוז",
      type: "B2",
      pic: '',
      house: "120 מטרים",
      porch: "40 מטרים",
    },
    {
      name: "5 חדרים - גן",
      type: "C",
      pic: '',
      house: "120 מטרים",
      garden: "180 מטרים",
    },
    {
      name: "5 חדרים - פנטהאוז",
      type: "D1",
      pic: '',
      house: "140 מטרים",
      porch: "50 מטרים",
    },
    {
      name: "5 חדרים - פנטהאוז",
      type: "E",
      pic: '',
      house: "142 מטרים",
      porch: "120 מטרים",
    },
    {
      name: "5 חדרים - פנטהאוז",
      type: "F",
      pic: '',
      house: "141 מטרים",
      porch: "110 מטרים",
    },
    {
      name: "6 חדרים - פנטהאוז",
      type: "D",
      pic: '',
      house: "157 מטרים",
      porch: "80 מטרים",
    },
  ];
  
  export const kineret_open ={
      txt : `על שפת הכינרת`,
  }
  
  export const kineret_imaging = [pool_area_above, pool_area, porch_kineret]
  
  export const kineret_about = `הפרויקט ממוקם בשכונת פארק נחל באר שבע, בלב השכונה בקו הראשון לפארק ולאגם המלאכותי הגדול בישראל. בפרויקט שישה בניינים בני חמש קומות, הכוללים בין תשע לעשר דירות בכל בניין, בנות 3 חדרים, 4 חדרים, 5 חדרים, דירות גן ודירות גג (פנטהאוז) בכל קומה שתי דירות בלבד, למעט דירות הגן ודירות הפנטהאוז שהן דירות יחידות בקומה. בכל הדירות מרפסות מרווחות המשקיפות לפארק ולאגם. החניון תת קרקעי עם הכנה לעמדות טעינה לרכב חשמלי.`
  export const kineret_neighborhood = `שכונת פארק נחל באר שבע, כוללת פארק עצום בגודלו עם מתקני ספורט רבים ומגוונים וטיילת המקיפה את כל הפארק, השכונה מוקפת במרכזי קניות, בילוי, תחבורה, מסחר, תעסוקה, וקרובה מאוד לכביש 6.`
     